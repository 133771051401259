import { RedocStandalone } from "redoc";

function App() {
  return (
    <RedocStandalone
      specUrl="dist.yaml"
      options={{
        hideDownloadButton: true,
      }}
    />
  );
}

export default App;
